import { css } from '@emotion/react';
// import React from 'react';
import colors, { shadows } from '../styles/colors';
import { textStyles } from '../styles/texts';

interface IProps {
  price: string;
  perItem: string;
  planName: string;
  bandText?: string;
  price3rdLine: React.ReactNode;
  features: React.ReactNode[];
  headerColor: string;
  bandColor: string;
  featuresHeaderColor: string;
}

const PlanDetails = (props: IProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 214px;
        min-width: 214px;
        align-self: flex-start;
        ${textStyles.xsRegular} /* text-align: center; */
					color: ${colors.textBlack};
        ${shadows.uniform}
      `}
    >
      <div
        css={css`
          background: ${props.headerColor};
          ${textStyles.sMedium}
          width: 100%;
          text-align: center;
          height: 28px;
          line-height: 28px;
          color: ${colors.textWhite};
        `}
      >
        {props.planName}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 32px;
          position: relative;
        `}
      >
        {props.bandText && (
          <div
            css={css`
              background: ${props.bandColor};
              width: 100%;
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 20px;
              line-height: 20px;
            `}
          >
            {props.bandText}
          </div>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <sup>$</sup>
              <div
                css={css`
                  ${textStyles.xxxlMedium}
                `}
              >
                {props.price}
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <div>
                per <b>{props.perItem}</b>,
              </div>
              <div>per month</div>
            </div>
          </div>
          <div>billed annually</div>
          <div>{props.price3rdLine}</div>
        </div>
        <div>
          <div
            css={css`
              color: ${props.featuresHeaderColor};
            `}
          >
            Feauters
          </div>
          <ul
            css={css`
              padding-left: 20px;
            `}
          >
            {props.features.map((x, idx) => (
              <li key={idx}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
